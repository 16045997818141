<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        xm="12"
        sm="5"
        md="5"
      >
        <div>
          <v-sheet
            tile
            height="55"
            class="d-flex"
          >
            <v-toolbar
              dark
              flat
              dense
              color="cyan "
            >
              <v-btn
                color="primary"
                small
                class="ma-2"
                @click="$refs.calendar.prev()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              
              <v-spacer />
              <v-btn
                small
                class="mr-1 black--text"
                color="amber"
                @click="mostrarorden"
              >
                Tarea
              </v-btn>
              
              <v-spacer />
              
              <v-btn
                small
                class="mr-1"
                color="red"
                @click="mostraractividad"
              >
                Actividad
              </v-btn>

              <v-spacer />
              
              <v-btn
                small
                class="mr-4"
                color="blue"
                @click="mostrarcita"
              >
                Cita
              </v-btn>
              
              <v-spacer />
              <v-btn
                color="primary"
                small
                @click="$refs.calendar.next()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-toolbar>
          </v-sheet>

          <v-sheet height="500">
            <v-calendar
              ref="calendar"
              v-model="value"
              locale="es"
              :weekdays="weekday"
              :type="type"
              :events="events"
              :event-overlap-mode="mode"
              :event-overlap-threshold="30"
              :event-color="getEventColor"
              @change="getEvents"
            >
              <template v-slot:day-body="{ date, week, timeToY }">
                <div
                  class="v-current-time"
                  :class="{ first: date === week[0].date }"
                  :style="{ top: nowY(timeToY) }"
                />
              </template>
            </v-calendar>
          </v-sheet>
        </div>
      </v-col>


      <v-col
        v-if="veractividad"
        cols="12"
        xm="12"
        sm="7"
        md="7"
      >
        <actividad />
      </v-col>

      <v-col
        v-if="vercita"
        cols="12"
        xm="12"
        sm="7"
        md="7"
      >
        <cita />
      </v-col>

      <v-col
        v-if="verorden"
        cols="12"
        xm="12"
        sm="7"
        md="7"
      >
        <orden />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters} from 'vuex'
import cita from '@/views/crm/citas/cita'
import actividad from '@/views/crm/actividades/actividad'
import orden from '@/views/crm/ordenes/addorden'

export default {
  components:{
    cita,
    actividad,
    orden,
  },
   
  data: () => ({
    idcontacto: 0,
    idproyecto:0,
    fecha1: (new Date((Date.now()-30) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    fecha2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

    idusuariosweb:0,
    vercita: false,
    veractividad: false,
    verorden: true,
    type: 'day',
    ready: false,
    types: ['Mes', 'Semana', 'Dia', '4 Dias'],
    mode: 'column',
    modes: ['column'],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: 'Lun - Sab', value: [0, 1, 2, 3, 4, 5, 6] },
      { text: 'Lun - Dom', value: [1, 2, 3, 4, 5, 6, 0] },
      { text: 'Lun - Vie', value: [1, 2, 3, 4, 5] },
      { text: 'Lun, Mie, Vie', value: [1, 3, 5] },
    ],
    value: '',
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
  }),
  computed: {
    ...mapGetters('login',['getdatosUsuario']),
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    
    
  },
  mounted () {
    this.ready = true
    // console.log('thiscal',this.cal);
    // console.log('thisnowy',this.nowY);
    this.scrollToTime()
    this.updateTime()

    this.$refs.calendar.scrollToTime('08:35')
    this.$refs.calendar.checkChange();
  },
  methods: {
    nowY (timeToY) {
      return this.cal ? timeToY(this.cal.times.now) + 'px' : '-10px'
    },

    // getEvents ({ start, end }) {
    //   const events = []

    //   const min = new Date(`${start.date}T00:00:00`)
    //   const max = new Date(`${end.date}T23:59:59`)
    //   const days = (max.getTime() - min.getTime()) / 86400000
    //   const eventCount = this.rnd(days, days + 20)

    //   for (let i = 0; i < eventCount; i++) {
    //     const allDay = this.rnd(0, 3) === 0
    //     const firstTimestamp = this.rnd(min.getTime(), max.getTime())
    //     const first = new Date(firstTimestamp - (firstTimestamp % 900000))
    //     const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
    //     const second = new Date(first.getTime() + secondTimestamp)

    //     events.push({
    //       name: this.names[this.rnd(0, this.names.length - 1)],
    //       start: first,
    //       end: second,
    //       color: this.colors[this.rnd(0, this.colors.length - 1)],
    //       timed: !allDay,
    //     })
    //   }

    //   this.events = events
    // },
    

    mostrarcita(){
      this.vercita  = true
      this.verorden = false
      this.veractividad = false
    },
    mostrarorden(){
      this.verorden = true
      this.vercita   = false
      this.veractividad = false
    },
    mostraractividad(){
      this.veractividad = true
      this.verorden     = false
      this.vercita      = false
    },



    getEvents(){
      console.log("getDatosusuario", this.getdatosUsuario)
      this.idusuariosweb = parseInt(this.getdatosUsuario.idusuariosweb)
        
      let payload = {
        fecha:    this.fecha1,
        fechafin : this.fecha2,
        idusuariosweb: this.idusuariosweb,
        idproyecto: 0,
        idcontacto: 0
      }
  
      console.log("payloadfiltros ", payload)
       
      //CARGAR CITAS
      this.$http.get("v2/citas.list" ).then(response=>{
        
        console.log("response CITAS ", response)
        if (response.data.error === null){
          // this.events = response.data.result

          response.data.result.forEach( element => {
            element.name = element.asunto
            element.details = element.temas

            let start2 = new Date(element.fechastart + ':00').getTime()
            let end2 = new Date(element.fechaend + ':00').getTime()
            element.timed = true
            element.start = start2
            element.end = end2
            // element.color = this.colors[this.rnd(0, this.colors.length - 1)]
            element.color = 'blue darken-3'
            this.events.push(element)
          })
          console.log("citas  offset", this.events)
        }
          
      }).catch(error=>{
        console.log(error)
      })

      // CARGAR ACTIVIDADES
      // this.$http.get("v2/actividades.list" ).then(response=>{
      this.$http.post("v2/con.actividades", payload ).then(response=>{
      
        if (response.data.error === null){
          // this.events = response.data.result

          response.data.result.forEach( element => {
            element.name = element.tipo
            element.details = element.obs
            element.start = element.fecha
            element.end = element.fechafin
            // element.color = this.colors[this.rnd(0, this.colors.length - 1)]
            element.color = 'red'
            this.events.push(element)
          })
          console.log("eventos actividades", this.events)
        }
          
      }).catch(error=>{
        console.log(error)
      })

    },


    getEventColor (event) {
      return event.color
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },

    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)
      // console.log("first", first)
      this.cal.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
  },
}
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
