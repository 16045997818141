<template>
  <v-container class="ma-0 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="12" xm="12">
        <v-text-field
          v-model="buscando"
          class="ma-0 pa-0"
          hint="idproyecto"
          append-icon="mdi-check-circle"
          prepend-icon="mdi-magnify"
          label="Buscar proyecto y dar enter"
          clearable
          :loading="loading"
          hide-details
          @click:append="buscarProyecto"
          @keyup.enter="buscarProyecto"
          @click:clear="limpiar"
          @keyup.113="buscarProyecto"
        />

        <h4 v-if="idproyecto !== ''" class="mx-3 my-0">ID: {{ idproyecto }}</h4>
      </v-col>
    </v-row>

    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="12" xm="12">
        <p v-if="proyectos.length > 0" class="ma-1">
          {{ proyectos.length }} resultados
        </p>

        <v-data-table
          v-if="proyectos.length > 0"
          ref="tabla"
          class="grey lighten-2"
          :headers="headers"
          :items="proyectos"
          item-key="idproyecto"
          :items-per-page="longitud"
          fixed-header
          dense
          :loading="loading"
          hide-default-header
          hide-default-footer
          height="300"
          @click:row="seleccionar"
        >
          <template v-slot:item="{ item }">
            <tr @click.once="seleccionar(item)">
              <td>{{ item.idproyecto }}</td>
              <td>{{ item.nomproy }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    buscando: '',
    longitud: 30,
    loading: false,

    proyectos: [],

    nomproy: '',
    idproyecto: '',
    //SNACKBAR
    text: '',
    snackbar: false,
    color: '',

    headers: [
      { text: 'Id', align: 'start', value: 'idproyecto' },
      { text: 'area', align: 'end', value: 'nomproy' }
    ]
  }),

  computed: {
    ...mapGetters('proyectos', ['getProyecto'])
  },

  watch: {
    getProyecto() {
      // this.limpiar()
      this.proyectosUpdate()
    }
  },

  created() {
    this.buscando = ''
    this.idproyecto = ''

    if (this.getProyecto.idproyecto == undefined) {
      this.buscando = ''
      this.idproyecto = ''
    } else {
      this.buscando = this.getProyecto.nomproy
      this.idproyecto = this.getProyecto.idproyecto
    }
  },

  methods: {
    ...mapActions('proyectos', ['setProyecto', 'setNewProyecto']),
    ...mapActions('snackbar', ['showSnack']),

    proyectosUpdate() {
      this.buscando = this.getProyecto.nomproy
      this.nomproy = this.getProyecto.nomproy
      this.idproyecto = this.getProyecto.idproyecto
    },

    buscarProyecto() {
      //ELIMINANDO BUSQUEDAS VACIAS
      if (this.buscando == null) return

      // console.log("a Buscar", this.buscando)
      var payload = {
        Buscacont: this.buscando.trim()
      }

      this.loading = true
      this.$http
        .post('v2/proyectos.find', payload)
        .then(response => {
          console.log(response.data)
          this.loading = false

          if (response.data.Total > 0) {
            this.proyectos = response.data.Data
            this.longitud = response.data.Total
            // console.log(" RESULTADOS",this.proyectos )
          } else {
            this.setNewProyecto(this.buscando)
            this.proyectos = []

            console.log('SIN RESULTADOS')
            this.showSnack({
              text: 'No se encontraron proyectos',
              color: 'info',
              timeout: 2000
            })
          }
        })
        .catch(() => {
          this.proyectos = []
          this.loading = false
          // console.log()
        })
    },

    limpiar() {
      this.proyectos = []
      this.idproyecto = ''
      this.nomproy = ''
      this.buscando = ''
      // var item= {}
      let Proyecto = {
        nomproy: '',
        idproyecto: '',
        numcont: '',
        empresa: '',
        telefono: '',
        numcli: '',
        email: '',
        celular: '',
        sucursal: '',
        departamento: ''
      }

      this.setProyecto(Proyecto)
    },

    seleccionar(item) {
      //console.log("row",row) // row.select(true);
      // console.log("item", item)
      this.nomproy = item.nomproy
      this.idproyecto = item.idproyecto

      this.buscando = item.nomproy
      this.proyectos = []
      this.setProyecto(item)
    }
  }
}
</script>
