<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      top
      color="info"
    >
      {{ text }}
      <v-btn
        color="white"
        text
        @click="snackbar = false"
      >
        Cerrrar
      </v-btn>
    </v-snackbar>

    <v-row>
      <v-col cols="12">
        <!-- TOOLBAR -->
        <v-toolbar
          dense
          dark
          height="50"
          flat
          class="rounded-xl d-sm"
          color="amber"
        >
          <v-card-title
            darl
            primary-title
          >
            {{ leyenda }}
          </v-card-title>

          <v-spacer />
          <v-btn
            color="green darken-1"
            small
            @click="cerrarorden"
          >
            Cerrar Orden
          </v-btn>

          <v-spacer />
          <v-btn
            align="right"
            color="orange"
            dark
            small
            class="ma-1"
            @click="regresar"
          >
            <v-icon>mdi-reply</v-icon>
          </v-btn>

          <v-btn
            small
            :loading="loading"
            :disabled="!valid"
            color="info"
            dark
            @click="saveInfo"
          >
            Procesar
          </v-btn>
        </v-toolbar>

        <!-- FORMA -->
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="12"
              md="5"
              sm="5"
              xm="12"
            >
              <v-row>
                <v-col class="mt-3 mb-3">
                  <v-card
                    class="rounded-lg"
                    elevation="1"
                  >
                    <!-- CONTACTOS -->
                    <leecontacto />

                    <v-text-field
                      v-model="empresa"
                      prepend-icon="mdi-briefcase"
                      hide-details
                      class="ma-4"
                      label="Empresa"
                      :rules="[rules.required]"
                    />

                    <v-text-field
                      v-model="email"
                      dense
                      prepend-icon="mdi-at"
                      hide-details
                      class="ma-4"
                      label="Email"
                      :rules="[rules.required]"
                    />

                    <v-text-field
                      v-model="telefono"
                      prepend-icon="mdi-phone"
                      hide-details
                      dense
                      class="ma-3"
                      :rules="[rules.required]"
                      label="Telefono"
                    />

                    <v-text-field
                      v-model="celular"
                      prepend-icon="mdi-cellphone-iphone"
                      hide-details
                      dense
                      class="ma-3"
                      label="Celular"
                    />

                    <v-text-field
                      v-model="sucursal"
                      dense
                      hide-details
                      class="ma-3"
                      prepend-icon="mdi-office"
                      label="Sucursal"
                    />

                    <v-text-field
                      v-model="departamento"
                      hide-details
                      dense
                      class="ma-3"
                      prepend-icon="mdi-account-group"
                      label="Departamento"
                    />
                    <leecliente />
                  </v-card>
                </v-col>
              </v-row>

              <v-row class="mt-2">
                <v-card>
                  <v-img
                    id="ordenimg"
                    src="@/assets/ordenes1.png"
                    contain
                  />
                </v-card>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              md="7"
              sm="7"
              xm="12"
            >
              <v-card
                height="30"
                class="rounded-xl"
              >
                <v-radio-group
                  v-model="prioridad"
                  dense
                  row
                >
                  <h4 class="ml-2">
                    Prioridad:
                  </h4>

                  <v-spacer />
                  <v-radio
                    label="Normal"
                    value="radio-1"
                    dense
                    color="amber"
                  />
                  <v-radio
                    color="amber"
                    dense
                    label="Urgente"
                    value="radio-2"
                  />
                </v-radio-group>
              </v-card>

              <v-card class="mt-5">
                <v-row>
                  <v-col class="mx-2">
                    <v-text-field
                      id="id"
                      v-model="titulo"
                      background-color="white"
                      outlined
                      dense
                      hide-details
                      label="Título de la orden."
                    />

                    <v-textarea
                      v-model="problema"
                      background-color="white"
                      auto-grow
                      outlined
                      class="mt-3"
                      hide-details
                      label="Descripción de tu problema"
                      :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <v-card
                class="mt-5 rounded-lg"
                color="amber"
                dark
              >
                <v-row>
                  <v-col class="mx-2">
                    <v-select
                      v-model="select"
                      dense
                      prepend-icon="reorder"
                      :items="tipoprob"
                      item-text="nomtipoprob"
                      item-value="idtipoprob"
                      label="Tipo de problema"
                      persistent-hint
                      return-object
                    />
                    <leeproyecto />
                    <v-text-field
                      id="id"
                      dark
                      label="Póliza de Soporte"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-card>

              <v-card
                class=" mt-5"
                flat
                height="65"
                rounded="xl"
              >
                <v-file-input
                  v-model="files"
                  prepend-icon="mdi-camera"
                  click:clear="limpiarfoto"
                  class="mx-2"
                  small-chips
                  accept="image/png, image/jpeg, image/bmp"
                  hint="Sube un archivo con la captura de el problema o copia y pega la captura"
                  label="Sube una captura del problema"
                  @change="cargar()"
                />

                <v-dialog
                  class="ma-0"
                  transition="dialog-bottom-transition"
                  max-height="1200"
                  max-width="900"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-col cols="12">
                      <v-card
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-img
                          :src="logo"
                          contain
                          max-height="auto"
                        />
                      </v-card>
                    </v-col>
                  </template>

                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar
                        color="primary"
                        dark
                        dense
                      >
                        Imagen del problema
                      </v-toolbar>

                      <v-card-text>
                        <!-- <div class="text-h2 pa-12">Hello world!</div> -->
                        <v-card>
                          <v-img
                            contain
                            :src="logo"
                            max-height="1200"
                            max-width="1200"
                          />
                        </v-card>
                      </v-card-text>

                      <v-card-actions class="justify-end">
                        <v-btn
                          text
                          @click="dialog.value = false"
                        >
                          Cerrar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <!-- Consultar Fotos {{ linkfoto }} -->
              <v-img
                elevation="10"
                :src="linkfoto"
                contain
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <!-- <v-toolbar
          color="amber"
          height="50"
        >
        </v-toolbar> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ordenes from '@/modules/ordenes/ordenesUsuarios'
import { mapGetters, mapActions } from 'vuex'
import leecontacto from '@/components/leecontacto'
import leecliente from '@/components/leecliente'
import leeproyecto from '@/components/leeproyecto'
import { format } from 'date-fns'
import horasait from '@/mixins/horasait.js'

export default {
  components: {
    leecontacto,
    leecliente,
    leeproyecto
  },
  mixins: [horasait],
  data: () => ({
    mostrarZoom: false,
    select: { nomtipoprob: '', idtipoprob: 1 },
    tipoprob: [],
    linkfoto: '',

    text: '',
    snackbar: false,

    celular: '',

    //MODO
    nModo: '',
    modo: '',

    titulo: '',
    problema: '',
    error: '',
    mensaje: '',

    valid: true,

    name: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters'
    ],

    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],

    rules: {
      required: value => !!value || 'Requerido.',
      min: v => v.length <= 10 || 'Mínimo 10 caracteres'
    },

    telefono: '',
    telefonoRules: [
      v => !!v || 'El telefono es requerido.',
      v => (v && v.length <= 10) || 'Máximo 10 caracteres'
    ],

    // FECHAS
    fechaini: new Date().toISOString().substr(0, 10),

    cerrada: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    creada: new Date().toISOString(),
    menu1: false,
    fechaorden: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date: '',

    //Hora
    time: null,
    inicio: '10:20:36',
    termino: '10:45:05',
    menu2: false,

    //Descricion textare
    descripcion: '',

    //EMPRESA
    empresa: '',
    empresaRules: [
      v => !!v || 'La empresa es requerida',
      v => (v && v.length <= 40) || 'Máximo 40 characters'
    ],
    prioridad: 'radio-1',
    logo: '@/assets/logo.jpg',

    idorden: 0,
    idcontacto: 0,
    idpolsop: 0,
    idproyecto: 0,
    idusuariosweb: 0,
    nomcont: '',
    //SUBIR FOTO EN ARCHIVO
    fileLogo: '',
    loading: false,

    files: [],
    imagen1: '',
    logourl: '',
    cargaTipoArchivo: false,

    sucursal: '',
    departamento: '',
    horaOrden: ''
  }),

  computed: {
    ...mapGetters('contactos', ['getContacto']),
    ...mapGetters('clientes', ['getCliente']),
    ...mapGetters('login', ['getdatosUsuario']),
    ...mapGetters('ordenes', ['getImagen']),

    validaInfo() {
      return (
        this.empresa !== '' &&
        this.email !== '' &&
        this.obs !== '' &&
        this.titulo !== ''
      )
    },

    leyenda() {
      return this.modo + ' Orden ' + (this.nModo == 1 ? '' : this.idorden)
    }
  },

  watch: {
    getContacto() {
      this.actualizarDatoscontactos()
    }

    // actualizarlogo(logourl){
    //   this.logo = logourl
    // },
  },

  mounted() {
    this.getDate()
    // console.log("this.getDate", this.getDate)
    // this.tomarFoto()
    document.addEventListener('paste', this.onPasteUpload)
  },

  created() {
    this.cargartipoprob()
    console.log('router', this.$route.params)
    let ruta = this.$route.params.addorden
    this.idusuariosweb = parseInt(this.getdatosUsuario.idusuariosweb)

    //MODO EDITAR
    if (ruta !== undefined) {
      this.nModo = 2
      this.readonly = true
      this.CargarInfo(this.$route.params.addorden)
      this.modo = 'Editar'
    } else {
      // console.log("nuevo")
      //VALORES INICIALES ALTA
      this.nModo = 1
      this.modo = 'Nueva'
    }
  },

  methods: {
    //Importar acciones del moudlo de ordenes
    ...mapActions('snackbar', ['showSnack']),
    ...mapActions('contactos', ['setContacto', 'setLimpiarContacto']),
    ...mapActions('ordenes', ['setImagen']),

    onPasteUpload(e) {
      // window.addEventListener("paste", processEvent)
      // function   processEvent(e) {
      var me = this
      for (var i = 0; i < e.clipboardData.items.length; i++) {
        // obtengo el clipboard item
        var clipboardItem = e.clipboardData.items[0]
        var type = clipboardItem.type

        // verifico si es una imagen
        if (type.indexOf('image') != -1) {
          // obtengo el contenido de la imagen BLOB
          var blob = clipboardItem.getAsFile()
          // console.log("blob", blob)
          // me.imagen1 = blob

          // creo un la URL del objeto
          var blobUrl = URL.createObjectURL(blob)
          // console.log("blob aqui objet url" ,blobUrl)

          me.logo = blobUrl

          // document.getElementById("imagenapegardiv").getElementsByTagName("img")[0].setAttribute("src", blobUrl);

          function toDataURL(url, callback) {
            var xhr = new XMLHttpRequest()
            xhr.onload = function() {
              var reader = new FileReader()
              reader.onloadend = function() {
                callback(reader.result)
              }
              reader.readAsDataURL(xhr.response)
            }
            xhr.open('GET', url)
            xhr.responseType = 'blob'
            xhr.send()
          }

          toDataURL(blobUrl, function(dataUrl) {
            me.imagen1 = dataUrl
            // this.actualizarlogo(dataUrl)
            // me.logo = dataUrl
            // console.log(dataUrl)
            ordenes.state.imagen = dataUrl
          })
        } else {
          console.log('No soportado ' + type)
        }
      }
    },

    limpiarfoto() {
      this.logo = ''
    },

    regresar() {
      this.$router.go(-1)
    },

    cargartipoprob() {
      // limpiar
      this.tipoprob = []
      this.loading = true

      this.$http
        .get('v2/get.nomtipoprob.activos')
        .then(response => {
          // console.log("response", response)
          if (response.data.error === null) {
            this.tipoprob = response.data.result
            this.loading = false
            // this.select  = response.data.result[0].nomtipoprob
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },

    cargar() {
      // console.log(this.files)
      if (this.files !== null) {
        console.log('hola')
        this.getBase64(this.files)
      }
      this.logo = this.logourl
      this.cargaTipoArchivo = true
    },
    getBase64(file) {
      var me = this
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function() {
        me.imagen1 = reader.result
        me.logo = reader.result
        ordenes.state.imagen = reader.result
        me.imagen1 = reader.result
        // console.log(reader.result);
      }
    },
    subirfoto() {
      console.log(this.files)
      this.nombreImagen = this.files.name
      this.size = this.files.size
      this.getBase64(this.files)
      // this.subirimg(this.files)
    },
    /////////////////////////////////////////////

    CargarInfo(addorden) {
      console.log('addorden', addorden)
      this.idorden = addorden.idorden

      this.titulo = addorden.titulo
      this.problema = addorden.problema

      this.creada = addorden.creada
      this.hrcreada = addorden.hrcreada
      // this.date = addorden.
      this.modulo = addorden.modulo

      //DATOS DEL CONTACTO
      this.nomcont = addorden.nomcont
      // this.nomuser= addorden.nomuser
      this.telefono = addorden.telefono
      this.celular = addorden.celular
      this.empresa = addorden.empresa
      this.email = addorden.email
      this.idcontacto = addorden.idcontacto

      //SELECT de tipo de probrobleme
      this.select.idtipoprob = addorden.idtipoprob
      this.select.nomtipoprob = addorden.modulo

      // console.log("select", this.select)

      this.src = '@/assets/logo.jpg'
      this.linkfoto =
        'https://www.soportesait.com/fotossait/' + this.idorden + '.png'
      this.logo =
        'https://www.soportesait.com/fotossait/' + this.idorden + '.png'

      let contacto = {
        nomcont: this.nomcont,
        idcontacto: this.idcontacto,
        empresa: this.empresa,
        celular: this.celular,
        telefono: this.telefono,
        email: this.email,
        sucursal: '',
        departamento: ''
      }

      this.setContacto(contacto)
    },

    getDate() {
      this.date = format(new Date(), ' H:mm:ss')
      setTimeout(this.getDate, 1000)
    },

    cerrarorden() {
      console.log(this.idorden)

      let payloadstatus = {
        idorden: this.idorden,
        cerrada: this.cerrada,
        estatus: 3
      }

      console.log(payloadstatus)
      this.loading = true

      this.$http
        .put('v2/cerrar.orden', payloadstatus)
        .then(response => {
          console.log('response', response)

          this.snackbar = true
          this.text = 'Su orden ha sido cerrada'
          this.loading = false

          this.$router.push({ name: 'misordenes' }).catch(error => {
            console.log(error)
          })
        })
        .catch(error => {
          console.log('error', error)
          this.snackbar = true
          this.text = 'Problemas al cerrar orden'
          this.loading = false
        })
    },

    saveInfo() {
      this.horaOrden = this.hora()
      this.fechaend = this.fechaorden + ' ' + this.horaOrden

      if (this.validaInfo === false) {
        this.snackbar = true
        this.text = 'Falta El titulo, La descripción o el Tipo de Problema'
        return
      }

      this.getContacto.nomcont
      // this.nomuser = this.nomcont

      let Contacto = {
        nomcont: this.getContacto.nomcont,
        telefono: this.telefono,
        celular: this.celular,
        empresa: this.empresa,
        email: this.email,
        sucursal: this.sucursal,
        departamento: this.departamento
      }

      let Ordenes = {
        creada: this.fechaini,
        hrcreada: this.date,
        problema: this.problema,
        tipo: 'Orden de Servicio',
        prioridad: 1,
        modulo: this.modulo,
        titulo: this.titulo,
        idusuariosweb: parseInt(this.idusuariosweb),
        idtipoprob: this.select.idtipoprob,
        idpolsop: this.idpolsop,
        modulo: this.select.nomtipoprob,
        tipo: this.select.nomtipoprob
      }

      // ACTIVIDAD
      let Crm = {
        tipo: 'Orden de Servicio',
        fecha: this.fechaend,
        fechafin: this.fechaend,
        obs: this.problema,
        actividad: 1,
        numuser: 'SAIT',
        numusergen: 'SAIT',
        idusuariosweb: parseInt(this.idusuariosweb)
      }

      let ordenModel = {
        contactos: Contacto,
        ordenes: Ordenes,
        crm: Crm
      }

      // En caso de de un contacto ya exista.
      // se debe mandar llamar a la api de actualizar contacto....

      if (this.getContacto.idcontacto != '') {
        ordenModel.contactos.idcontacto = this.idcontacto
      }
      console.log('orden Model', ordenModel)

      // Descripción de la transacción
      // 1. Agregar un contacto.
      // 2. Agregar una Orden.
      // 3. Agregar un CRM.
      this.loading = true

      if (this.nModo == 1) {
        this.$http
          .post('v2/addorden', ordenModel)
          .then(response => {
            console.log('response', response)
            this.idorden = response.data.result.Crm.idorden
            console.log('idorden', this.idorden)

            this.subirImagen()
            this.enviarcorreo(this.idorden)

            this.snackbar = true
            this.text = 'Su orden ha sido procesada'
            this.loading = false

            this.$router.push({ name: 'misordenes' }).catch(error => {
              console.log(error)
            })
          })
          .catch(error => {
            console.log('error', error)
            this.snackbar = true
            this.text = 'Problemas al grabar orden'
            this.loading = false
          })
      }

      if (this.nModo == 2) {
        console.log('pendiente')
        this.$router.push({ name: 'misordenes' }).catch(error => {
          console.log(error)
        })
      }
    },

    enviarcorreo(idorden) {
      const cIdorden = idorden.toString()
      const payloadEmail = {
        email: this.email,
        cuerpo: 'Correos',
        subject: cIdorden + this.modulo,
        fecha: this.fechaini + '  ' + this.date,
        celular: this.celular,
        telefono: this.telefono,
        Idordenes: idorden,
        Tipoproblema: this.modulo,
        Nomcli: this.nomuser,
        img: 'https://www.soportesait.com/fotossait/' + cIdorden + '.png',
        detalle: this.problema
      }

      // console.log("IMG correo", payloadEmail)
      this.$http
        .post('v2/sendcorreo', payloadEmail)
        .then(respuesta => {
          console.log('respuesta email', respuesta)
          // this.$router.push({name: 'misordenes'}).catch(error => { console.log(error) })
        })
        .catch(error => {
          console.log('error', error)
        })
    },

    subirImagen() {
      const contentType = 'image/png'
      let fomrData = new FormData()
      //copy paste
      if (this.cargaTipoArchivo === false) {
        const blobFin = this.b64toBlob(ordenes.state.imagen, contentType)
        this.imagen = new File([blobFin], this.idorden.toString() + '.png')
        fomrData.append('file', this.imagen)
      } else {
        console.log(this.files.name)
        fomrData.append('file', this.files, this.idorden.toString() + '.png')
      }

      //se crea el objeto y se le agrega como un apendice el archivo
      // console.log("form-data get",fomrData
      this.$http
        .post('v2/subirimagen', fomrData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log('error', error)
        })
    },

    //Función para convertir una imagen a un archivo
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      var img = b64Data.substring(22, b64Data.length)
      const byteCharacters = atob(img)
      const byteArrays = []

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },

    // limpiarOrden() {

    //   // this.leecontacto = ''
    //   this.titulo
    //   this.problema = ''
    //   this.t = ''
    //   this.leecliente = ''
    //   this.tipoopor= ''
    //   this.asunto= ''
    //   this.conocien =''
    //   this.obs = ''

    //   this.setLimpiarContacto()
    //   this.setLimpiarCliente()

    // },

    actualizarDatoscontactos() {
      console.log('actualizarDatoscontactos', this.getContacto)

      this.celular = this.getContacto.celular
      this.empresa = this.getContacto.empresa
      this.idcontacto = parseInt(this.getContacto.idcontacto)
      this.email = this.getContacto.email
      this.numcli = this.getContacto.numcli
      this.numcont = this.getContacto.numcont
      this.nomcont = this.getContacto.nomcont

      this.sucursal = this.getContacto.sucursal
      this.telefono = this.getContacto.telefono
      this.departamento = this.getContacto.departamento
    },

    hora() {
      var f = new Date()
      var hora = f.getHours()
      var minuto = f.getMinutes()
      var segundo = f.getSeconds()
      if (hora < 10) {
        hora = '0' + f.getHours()
      }
      if (minuto < 10) {
        minuto = '0' + f.getMinutes()
      }
      if (segundo < 10) {
        segundo = '0' + f.getSeconds()
      }

      // return hora + ":" + minuto + ":" + segundo
      return hora + ':' + minuto
    }
  }
}
</script>

<style scoped>
.ordenimg {
  top: -50px;
}
</style>
